<template>
    <div>
        <fieldset>
            <fieldset>
                <Field
                    v-model="form.passportData.passportseries"
                    :error="formErrors.passportData.passportseries"
                    title="Серия паспорта"
                    mask="## ##"
                    autoTab="number"
                    valueWithoutMask
                    placeholder="22 20"
                />
                <Field
                    v-model="form.passportData.passportnumber"
                    :error="formErrors.passportData.passportnumber"
                    title="Номер"
                    name="number"
                    autoTab="issuecode"
                    mask="### ###"
                    valueWithoutMask
                    placeholder="123 456"
                />
            </fieldset>
            <fieldset>
                <Field
                    v-model="form.passportData.passportissuecode"
                    :error="formErrors.passportData.passportissuecode"
                    title="Код подразделения"
                    name="issuecode"
                    autoTab="issuedate"
                    mask="###-###"
                    valueWithoutMask
                    placeholder="000-000"
                />
                <Date
                    title="Дата выдачи"
                    name="issuedate"
                    v-model="form.passportData.passportissuedate"
                    :error="formErrors.passportData.passportissuedate"
                    placeholder="дд.мм.гггг"
                />
            </fieldset>
        </fieldset>
        <fieldset>
            <Address
                title="Адрес регистрации"
                v-model.trim="form.contactData.addrcity"
                :error="formErrors.contactData.addrcity"
            />
        </fieldset>
    </div>
</template>

<script>
import Date from '@/components/form/field/Date'
import Address from '@/components/form/search/Address'

import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import { year, month, day } from '@/helpers/date/currentDate'
const TODAY = `${day}.${month}.${year}`

const ISSUE_DATE_VALIDATE = [Validation.DATE, [Validation.DATE_LESS, TODAY]]

import { mapGetters } from 'vuex'

export default {
    name: 'PassportInformation',
    mixins: [
        validation
    ],
    data() {
        return {
            form: {
                contactData: {
                    addrcity: '',
                },
                passportData: {
                    passportissuecode: '',
                    passportnumber: '',
                    passportseries: '',
                    passportissuedate: ''
                }
            },
            formRules: {
                contactData: {
                    addrcity: [Validation.REQUIRED]
                },
                passportData: {
                    passportissuecode: [
                        Validation.REQUIRED,
                        [Validation.MIN, 6],
                        [Validation.MAX, 6]
                    ],
                    passportnumber: [
                        Validation.REQUIRED,
                        [Validation.MIN, 6],
                        [Validation.MAX, 6]
                    ],
                    passportseries: [
                        Validation.REQUIRED,
                        [Validation.MIN, 4],
                        [Validation.MAX, 4]
                    ],
                    passportissuedate: ISSUE_DATE_VALIDATE
                }
            },
            customErrors: {
                passportData: {
                    passportissuedate: {
                        [Validation.DATE_LESS]: 'Дата не может быть больше текущей',
                    },
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'application/user'
        })
    },
    created() {
        this.initData()
    },
    methods: {
        initData() {
            this.form.contactData.addrcity = this.user.contactData.addrcity
            this.form.passportData.passportissuecode = this.user.passportData.passportissuecode
            this.form.passportData.passportnumber = this.user.passportData.passportnumber
            this.form.passportData.passportseries = this.user.passportData.passportseries
            this.form.passportData.passportissuedate = this.user.passportData.passportissuedate
        }
    },
    components: {
        Address,
        Date
    }
}
</script>
