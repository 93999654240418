import Router from '@/router'

export default function (path, params = {}) {
    switch (path) {
        case 'poll/passport':
        case 'passport': {
            Router.push({
                name: 'LoanPassport',
                params
            })
            break;
        }
        case 'poll/before':
        case 'before': {
            Router.push({
                name: 'LoanBefore',
                params
            })
            break;
        }
        case 'poll/final':
        case 'final': {
            Router.push({
                name: 'Final',
                params
            })
            break;
        }
        case 'poll/card':
        case 'card': {
            Router.push({
                name: 'LoanCard',
                params: {
                    ...params,
                    allow: true
                }
            })
            break;
        }
        default: {
            Router.push({
                name: 'LoanPrimary'
            })
        }
    }
}